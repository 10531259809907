import React from "react";
import { StyleSheet, TextInput } from "react-native-web";
import DefaultTheme from "../../themes";

const CustomTextInput = ({
  autoCapitalize,
  autoComplete,
  autoCorrect,
  editable,
  inputMode,
  keyboardType,
  multiline,
  onChangeText,
  onEndEditing,
  placeholder,
  readOnly,
  secureTextEntry,
  style,
  textContentType,
  value,
  noBorderBottom
}) => {
  return (
    <TextInput
      autoCapitalize={autoCapitalize}
      autoComplete={autoComplete}
      autoCorrect={autoCorrect}
      editable={editable}
      inputMode={inputMode}
      keyboardType={keyboardType}
      multiline={multiline}
      onChangeText={onChangeText}
      onEndEditing={onEndEditing}
      placeholder={placeholder}
      secureTextEntry={secureTextEntry}
      style={[styles.text_input, readOnly && styles.text_input_readonly,noBorderBottom ? null : styles.borderBottom, style]}
      textContentType={textContentType}
      value={value}
      className={styles.text_input}
      placeholderTextColor={DefaultTheme.COLORS.grey2}
    />
  );
};

const styles = StyleSheet.create({
  text_input: {
    fontFamily: DefaultTheme.FONTS.Regular,
    height: 37,
    borderColor: DefaultTheme.COLORS.grey5,
    fontSize: DefaultTheme.FONT_SIZES.text_input,
    outlineWidth: 0,
  },
  text_input_readonly: {
    borderColor: DefaultTheme.COLORS.grey2,
    color: DefaultTheme.COLORS.grey2,
  },
  borderBottom : {
    borderBottomWidth: 1
  }
});

export default CustomTextInput;
