import React, { useState, useContext, useEffect } from "react";
import DefaultTheme from "../../themes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  View,
  StyleSheet,
  Pressable,
} from "react-native-web";
import {ReactComponent as SvgComponent} from "../../assets/images/authentication/login.svg";
import {ReactComponent as Password} from "../../assets/icons/password.svg";
import {ReactComponent as Password2} from "../../assets/icons/password2.svg";
import Button from "../../components/Button";
import AppLayout from "../../appLayout/AppLayout";
import ModalView from "../../components/Modal";
import AuthService from "../../services/auth-service";
import BuybyeService from "../../services/buybye-service";
import { AuthContext } from "../../store/auth-context";
import TextInlineWithLinks from "../../components/UI/TextInlineWithLinks";
import SmallParagraph from "../../components/UI/SmallParagraph";
import CustomTextInput from "../../components/UI/CustomTextInput";
import H2Title from "../../components/UI/H2Title";
import localStorageUser from "../../localStorage/user";
import {
  noResponseParams,
  notOkPageParams,
} from "../../components/Helpers/ResultMessagesHelper";


export default function LogIn({ navigation, route }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const userEmail = history.location?.email;
  const card = history.location?.card;
  const purchaseId = history.location?.purchase;

  const [email, onChangeEmail] = useState("");
  const [password, onChangePassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [id, setId] = useState("");
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showErrorUserEmptyMessage, setShowErrorUserEmptyMessage] =
    useState(false);
  const [showErrorPasswordEmptyMessage, setShowErrorPasswordEmptyMessage] =
    useState(false);
  const [showErrorInvalidUserMessage, setShowErrorInvalidUserMessage] =
    useState(false);
  const [showErrorWrongPasswordMessage, setShowErrorWrongPasswordMessage] =
    useState(false);
  const [keepLoggedIn, setkeepLoggedIn] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const authCtx = useContext(AuthContext);

  useEffect(() => { onChangeEmail(userEmail ? userEmail : "") }, [userEmail]);

  const modalButtons = [
    {
      title: t("buttons:logInReactivateAccount"),
      type: "PRIMARY",
      onPress: () => proceedWithLogin(),
    },
    {
      title: t("buttons:cancel"),
      type: "LINK",
      onPress: () => { setModalVisible(false) },
    },
  ];

  const userIsEmpty = email?.trim().length === 0;
  const passwordIsEmpty = password?.trim().length === 0;

  async function updateUserWithCard(userId) {
    const getUser = await localStorageUser.getUser();
    if (typeof getUser.result === "string") {
      getUser.result = JSON.parse(getUser.result);
    }
    if (getUser.result) {
      let res = await AuthService.updateUser(
        {
          otherIdentifiers: [...(getUser.result?.otherIdentifiers || []), card],
        },
        userId
      );
      if (res.success) {
        await AuthService.getUser({ idUser: userId });
      }
    }
  }

  const sendEmailBuyBye = async() => {
    let sendEmail = await BuybyeService.sendEmailReceipt(purchaseId, {
      contact: email,
      registered: true,
    });
  }

  async function proceedWithLogin() {
    let res = await AuthService.deleteUser({ toDelete: false }, id);
    if (res.success) {
      setShowErrorMessage(false);
      setModalVisible(false);
      await authCtx.authenticate();
      await AuthService.getUser({ idUser: id });
      if (card){
        await updateUserWithCard(id);
      }
      await sendEmailBuyBye();
      history.push("Purchases");
    } else if (res.errorMessage.noResponse) {
      history.push("/error", {
        params: noResponseParams,
      });
    } else {
      history.push("/error", {
        params: notOkPageParams(res.errorMessage.message + "\n " + t("resultScreens:tryAgainLater"))
      });
    }
  }

  async function loginHandler() {
    userIsEmpty
      ? setShowErrorUserEmptyMessage(true)
      : setShowErrorUserEmptyMessage(false);
    passwordIsEmpty
      ? setShowErrorPasswordEmptyMessage(true)
      : setShowErrorPasswordEmptyMessage(false);
    if (!userIsEmpty && !passwordIsEmpty) {
      let res = await AuthService.login({ email, password });
      if (res.success) {
        await setId(res.result.user._id);
        if (res.result.user.toDelete) {
          setModalVisible(true);
        } else {
          setShowErrorMessage(false);
          setModalVisible(false);
          await authCtx.authenticate();
          i18n.changeLanguage(res.result.user.locale);
          if (card){
            await updateUserWithCard(res.result.user._id);
            await sendEmailBuyBye();
          }
          history.push("Purchases");
        }
      } else {
        if (res.errorMessage.validation === false) {
          history.push({
            pathname: "/SignIn/UserNotValidated",
            email: email,
          });
        } else if (res.errorMessage.invalidUser === true) {
          setShowErrorInvalidUserMessage(true);
        } else if (res.errorMessage.wrong === true) {
          setShowErrorWrongPasswordMessage(true);
          setShowErrorMessage(false);
        } else if (res.errorMessage.noResponse) {
          history.push("/error", {
            params: noResponseParams,
          });
        } else {
          setShowErrorInvalidUserMessage(false);
          setShowErrorWrongPasswordMessage(false);
          setShowErrorMessage(true);
        }
      }
    }
  }

  return (
    <AppLayout
      headerBack={false}
      route={route}
      history={history}
      withScrollview={false}
      styleToContainer={{ paddingTop: "10vh" }}
    >
      <View style={{ display: "flex", height: "100%", paddingTop: "3%" }}>
        <View
          style={{
            alignItems: "center",
            justifyContent: "flex-start",
            maxHeight: "30%",
          }}
        >
          <SvgComponent style={{ maxHeight: "100%" }} />
        </View>
        <H2Title style={{ marginBlock: "5vh" }}>Welcome back</H2Title>
        <View
          style={{
            justifyContent: "flex-start",
            marginTop: "5vh",
          }}
        >
          <View
            style={{
              paddingBottom:
                showErrorUserEmptyMessage || showErrorInvalidUserMessage
                  ? 24.4
                  : 52,
            }}
          >
            <CustomTextInput
              style={[
                showErrorMessage ||
                showErrorInvalidUserMessage ||
                showErrorUserEmptyMessage
                  ? { borderBottomColor: DefaultTheme.COLORS.error }
                  : null,
              ]}
              onChangeText={onChangeEmail}
              value={email}
              placeholder={t("authentication:emailAddress") + "*"}
              keyboardType="email-address"
              textContentType="emailAddress"
              autoComplete="email"
              autoCapitalize="none"
              autoCorrect={false}
              onEndEditing={() => {
                if (userIsEmpty) {
                  setShowErrorUserEmptyMessage(true);
                } else {
                  setShowErrorUserEmptyMessage(false);
                }
              }}
            />
            {(showErrorUserEmptyMessage || showErrorInvalidUserMessage) && (
              <SmallParagraph style={{ color: DefaultTheme.COLORS.error }}>
                {showErrorUserEmptyMessage && t("errors:emailEmpty") + " "}
                {showErrorInvalidUserMessage && t("errors:emailInvalid")}
              </SmallParagraph>
            )}
          </View>
          <View
            style={{
              paddingBottom: showErrorMessage
                ? 0
                : showErrorWrongPasswordMessage || showErrorPasswordEmptyMessage
                ? 24.4
                : 52,
            }}
          >
            <View
              style={[
                styles.password_container,
                showErrorMessage ||
                showErrorPasswordEmptyMessage ||
                showErrorWrongPasswordMessage
                  ? { borderBottomColor: DefaultTheme.COLORS.error }
                  : null,
              ]}
            >
              <CustomTextInput
                onChangeText={onChangePassword}
                value={password}
                placeholder={t("authentication:password") + "*"}
                textContentType="password"
                secureTextEntry={secureTextEntry}
                style={{
                  overflow: "hidden",
                  width: "90%",
                  fontSize: 16,
                  borderBottomWidth: 0,
                }}
                autoCorrect={false}
                onEndEditing={() => {
                  if (passwordIsEmpty) {
                    setShowErrorPasswordEmptyMessage(true);
                  } else {
                    setShowErrorPasswordEmptyMessage(false);
                  }
                }}
              />
              <Pressable
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "8%",
                }}
                onPress={() => {
                  setSecureTextEntry(secureTextEntry ? false : true);
                }}
              >
                {secureTextEntry ? (
                  <Password style={{ alignSelf: "center" }} />
                ) : (
                  <Password2 style={{ alignSelf: "center" }} />
                )}
              </Pressable>
            </View>
            {(showErrorMessage ||
              showErrorPasswordEmptyMessage ||
              showErrorWrongPasswordMessage) && (
              <SmallParagraph style={{ color: DefaultTheme.COLORS.error }}>
                {showErrorPasswordEmptyMessage &&
                  t("errors:passwordEmpty") + " "}
                {showErrorWrongPasswordMessage &&
                  t("errors:passwordWrong") + " "}
                {showErrorMessage && t("errors:noMatchEmailAndPassword")}
              </SmallParagraph>
            )}
          </View>
        </View>
        <View
          style={{
            paddingBottom: 20,
          }}
        >
          {/* <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <Checkbox
              icon={<Icon.FiCheck size={14} stroke-width={3} />}
              name="my-input"
              checked={false}
              borderColor={DefaultTheme.COLORS.grey2}
              style={{ cursor: "pointer", borderWidth: "1px" }}
              labelStyle={{ marginLeft: 5, userSelect: "none" }}
              onChange={() => {
                setChecked(!checked);
                setkeepLoggedIn(checked);
              }}
            />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Text
                style={{ color: DefaultTheme.COLORS.grey4, fontSize: "12px" }}
              >
                Keep me logged in.
              </Text>
            </View>
          </View> */}
          <Button
            text={t("buttons:logIn")}
            onPress={loginHandler}
            type="PRIMARY"
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignSelf: "center",
              marginTop: 24,
            }}
          >
            <TextInlineWithLinks>
              {t("authentication:forgotPassword")}
            </TextInlineWithLinks>
            <Button
              text={t("buttons:resetHere")}
              onPress={() => {
                history.push({
                  pathname: "/ForgotPassword",
                  email: email,
                });
              }}
              type="LINK"
            />
          </View>
        </View>
      </View>
      <ModalView
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title={t("authentication:loginDeletedAccountModalTitle")}
        subTitle={t("authentication:loginDeletedAccountModalText")}
        buttons={modalButtons}
      />
    </AppLayout>
  );
}

const styles = StyleSheet.create({
  password_container: {
    fontFamily: DefaultTheme.FONTS.Regular,
    height: 40,
    borderBottomWidth: 1,
    borderColor: DefaultTheme.COLORS.grey5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
