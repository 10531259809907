import React, { useState, useEffect } from "react";
import {
  Text,
  StyleSheet,
  Pressable,
  View
} from "react-native-web";
/* import { MaterialIndicator } from "react-native-indicators"; */
import { useTranslation } from "react-i18next";

import DefaultTheme from "../themes";

const Button = ({ onPress, element, text, type = 'PRIMARY', Icon, loading }) => {

  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    var flag = element === "" ? true : false;
    setDisabled(flag);
  }, [element]);

  return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      style={({ pressed }) => [
        styles.container,
        styles[`container_${type}`],
        disabled
          ? styles[`container_${type}_disabled`]
          : pressed
            ? styles[`container_${type}_pressed`]
            : null,
        loading ? styles[`container_${type}_loading`] : null,
      ]}
      children={({ pressed }) => (
        <>
          {loading ? (
            <View style={{ paddingRight: 12 }}>
              {/*                   <MaterialIndicator
                    color={
                      type === "PRIMARY"
                        ? "rgba(255, 255, 255, 1)"
                        : "rgba(0, 74, 153, 1)"
                    }
                    size={24}
                    trackWidth={24 / 12}
                  /> */}
            </View>
          ) : (
            Icon && (
              <View style={[
                styles[`icon_btn_${type}`],
                pressed ? styles[`icon_btn_${type}_pressed`] : null,
              ]}>
                <Icon />
              </View>
            )
          )}
          <Text
            className="text"
            style={[
              styles.text,
              styles[`text_${type}`],
              disabled
                ? styles[`text_${type}_disabled`]
                : pressed
                  ? styles[`text_${type}_pressed`]
                  : null,
              loading ? styles[`text_${type}_loading`] : null,
            ]}
          >
            {loading ? t("buttons:processing") : text}
          </Text>
        </>
      )}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: 20,
    alignItems: "center",
    fontWeight: 16,
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: DefaultTheme.BUTTONS.borderRadius,
  },
  text: {
    fontSize: DefaultTheme.BUTTONS.fontSize,
    lineHeight: 19,
    fontFamily: DefaultTheme.FONTS.Bold,
  },

  // Primary Button
  container_PRIMARY: {
    backgroundColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.primary_background],
  },
  container_PRIMARY_pressed: {
    backgroundColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.primary_background_pressed],
  },
  container_PRIMARY_disabled: {
    backgroundColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.primary_background_disabled],
  },
  container_PRIMARY_loading: {
    backgroundColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.primary_background_loading],
  },
  text_PRIMARY: {
    color: DefaultTheme.COLORS[DefaultTheme.BUTTONS.primary_text_color],
  },
  text_PRIMARY_pressed: {
    color: DefaultTheme.COLORS[DefaultTheme.BUTTONS.primary_text_color_pressed],
  },
  text_PRIMARY_disabled: {
    color:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.primary_text_color_disabled],
  },
  text_PRIMARY_loading: {
    color: DefaultTheme.COLORS[DefaultTheme.BUTTONS.primary_text_color_loading],
  },
  icon_btn_PRIMARY: {
    marginRight: DefaultTheme.BUTTONS.iconMarginRight,
    color: DefaultTheme.COLORS.white,
  },

  // Secondary Button
  container_SECONDARY: {
    borderWidth: DefaultTheme.BUTTONS.secondary_border_width,
    backgroundColor:
      DefaultTheme.BUTTONS[DefaultTheme.BUTTONS.secondary_background],
    borderColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.secondary_border_color],
  },
  container_SECONDARY_pressed: {
    backgroundColor:
      DefaultTheme.BUTTONS[DefaultTheme.BUTTONS.secondary_background_pressed],
    borderColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.secondary_border_color_pressed],
  },
  container_SECONDARY_disabled: {
    backgroundColor:
      DefaultTheme.BUTTONS[DefaultTheme.BUTTONS.secondary_background_disabled],
    borderColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.secondary_border_color_disabled],
  },
  container_SECONDARY_loading: {
    backgroundColor:
      DefaultTheme.BUTTONS[DefaultTheme.BUTTONS.secondary_background_loading],
    borderColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.secondary_border_color_loading],
  },
  text_SECONDARY: {
    color: DefaultTheme.COLORS[DefaultTheme.BUTTONS.secondary_text_color],
  },
  text_SECONDARY_pressed: {
    color:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.secondary_text_color_pressed],
  },
  text_SECONDARY_disabled: {
    color:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.secondary_text_color_disabled],
  },
  text_SECONDARY_loading: {
    color:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.secondary_text_color_loading],
  },
  icon_btn_SECONDARY: {
    marginRight: DefaultTheme.BUTTONS.iconMarginRight,
  },

  // Link Button
  container_LINK: {
    width: "auto",
    paddingVertical: 0,
    paddingHorizontal: 4,
    marginVertical: 0,
    borderRadius: 0,
    marginBottom: 0,
    marginTop: 0,
    backgroundColor: DefaultTheme.COLORS[DefaultTheme.BUTTONS.link_background],
  },
  container_LINK_pressed: {
    backgroundColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.link_background_pressed],
  },
  container_LINK_disabled: {
    backgroundColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.link_background_disabled],
  },
  text_LINK: {
    fontSize: DefaultTheme.BUTTONS.link_font_size,
    fontFamily: DefaultTheme.FONTS[DefaultTheme.BUTTONS.link_font_family],
    color: DefaultTheme.COLORS[DefaultTheme.BUTTONS.link_text_color],
    lineHeight: DefaultTheme.BUTTONS.link_line_height,
  },
  text_LINK_pressed: {
    color: DefaultTheme.COLORS[DefaultTheme.BUTTONS.link_text_color_pressed],
  },
  text_LINK_disabled: {
    color: DefaultTheme.COLORS[DefaultTheme.BUTTONS.link_text_color_disabled],
  },

  // Primary Round Button
  container_PRIMARY_ROUND: {
    backgroundColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.primary_round_background],
    width: 60,
    height: 60,
    borderRadius: 100,
    paddingHorizontal: 20,
  },
  container_PRIMARY_ROUND_pressed: {
    backgroundColor:
      DefaultTheme.COLORS[
      DefaultTheme.BUTTONS.primary_round_background_pressed
      ],
  },
  icon_btn_PRIMARY_ROUND: {
    color: DefaultTheme.COLORS[DefaultTheme.BUTTONS.primary_round_icon_color],
  },
  icon_btn_PRIMARY_ROUND_pressed: {
    color:
      DefaultTheme.COLORS[
      DefaultTheme.BUTTONS.primary_round_icon_color_pressed
      ],
  },

  // Secondary Round Button
  container_SECONDARY_ROUND: {
    backgroundColor:
      DefaultTheme.COLORS[DefaultTheme.BUTTONS.secondary_round_background_pressed],
    width: 60,
    height: 60,
    borderRadius: 100,
    paddingHorizontal: 0,
    shadowColor: "#0000000D",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 2.9,
    elevation: 4,
  },
  container_SECONDARY_ROUND_pressed: {
    backgroundColor:
      DefaultTheme.COLORS[
      DefaultTheme.BUTTONS.secondary_round_background
      ],
  },
  text_SECONDARY_ROUND_pressed: {
    color:
      DefaultTheme.COLORS.white,
  },
  icon_btn_SECONDARY_ROUND: {
    color: DefaultTheme.COLORS.white,
  },
  icon_btn_SECONDARY_ROUND_pressed: {
    color: DefaultTheme.COLORS[DefaultTheme.BUTTONS.secondary_round_icon_color_pressed],
  },
});

export default Button;
