
const { height } =window.innerHeight;

export default {
  COLORS: {
    primary: "#001F2D",
    secondary: "#4D626C",
    white: "#FFFFFF",
    border: "#F3F4F8",
    grey5: "#2A292A",
    grey4: "#5F5F5F",
    grey3: "#949494",
    grey2: "#CAC9CA",
    grey1: "#F1F0EA",
    color5: "#85CCB6",
    color4: "#F4646C",
    color3: "#FFCA39",
    color2: "#ADD569",
    color1: "#86B4D0",
    error: "#CC3737",
    warning: "#FFA51E",
    success: "#4ABA63",
    transparent: "transparent",
  },
  FONTS: {
    Regular:  "Roboto-Regular",
    Bold: "Americane-Bold",
    Goodlife: "Goodlife"
  },
  FONT_SIZES: {
    header_title: 26,
    h1: 24,
    h2: 22,
    p: 16,
    small: 11,
    text_input: 18
  },
  BUTTONS: {
    borderRadius: 20,
    fontSize: 18,
    iconMarginRight: 14,
    primary_background: "color5",
    primary_text_color: "white",
    primary_background_pressed: "grey5",
    primary_text_color_pressed: "white",
    primary_background_disabled: "grey3",
    primary_text_color_disabled: "grey2",
    primary_background_loading: "grey5",
    primary_text_color_loading: "grey1",
    secondary_background: "transparent",
    secondary_border_width: 1,
    secondary_border_color: "grey5",
    secondary_text_color: "grey5",
    secondary_background_pressed: "grey2",
    secondary_border_color_pressed: "grey2",
    secondary_text_color_pressed: "grey5",
    secondary_background_disabled: "transparent",
    secondary_border_color_disabled: "color5",
    secondary_text_color_disabled: "color5",
    secondary_background_loading: "transparent",
    secondary_border_color_loading: "color5",
    secondary_text_color_loading: "color5",
    link_background: "white",
    link_background_pressed: "white",
    link_background_disabled: "white",
    link_text_color: "color5",
    link_text_color_pressed: "grey5",
    link_text_color_disabled: "grey2",
    link_font_size: 16,
    link_line_height: 19.7,
    link_font_family: "Bold",
    primary_round_background: "color5",
    primary_round_background_pressed: "color4",
    primary_round_icon_color: "white",
    primary_round_icon_color_pressed: "white",
    secondary_round_background: "grey5",
    secondary_round_background_pressed: "color5",
    secondary_round_icon_color: "grey5",
    secondary_round_icon_color_pressed: "grey1",
  },
  HOME: {
    header_with_logo: false,
    bg_img_close_menu_scale: 2.1,
    bg_img_close_menu_scale_small_height: 1.75,
    bg_img_open_menu_scale: 1,
    bg_img_open_menu_scale_small_height: 0.8,
    bg_img_translate_Y_percentage: 0.25,
    bg_img_translate_X_percentage: 0,
    carousel_height: height * 0.55,
    carousel_height_small_screens: 395,
  },
  MENU: {
    background: "color3",
    text_color: "white",
    font_family: "Goodlife",
    language_submenu_background: "color4",
  },
  GLOBAL_SPACES: {
    paddingHorizontal: "5.7%",
  },
};
  