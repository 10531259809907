import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  View,
  StyleSheet,
  TextInput,
  Pressable,
  Text,
} from "react-native-web";
import DefaultTheme from "../../themes";
import {ReactComponent as Password} from "../../assets/icons/password.svg";
import {ReactComponent as Password2} from "../../assets/icons/password2.svg";
import Button from "../../components/Button";
import AppLayout from "../../appLayout/AppLayout";
import authService from "../../services/auth-service";
import {
  noResponseParams,
  notOkPageParams,
} from "../../components/Helpers/ResultMessagesHelper";
import BottomBtnContainer from "../../components/UI/BottomBtnContainer";
import SmallParagraph from "../../components/UI/SmallParagraph";
import { useHistory } from "react-router-dom";

export default function ForgotPassword1() {
  const { t } = useTranslation();

  const [password, onChangePassword] = useState("");
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [showPasswordInvalidMsg, setShowPasswordInvalidMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const userId = history.location?.userId;

  const PASSWORD_REGEX = new RegExp(
    /^((?!.*[\s])(?=.*?[a-z])(?=.*?[^a-zA-Z]).{8,})/
  );
  const passwordIsValid =
    password.trim().length > 0 && PASSWORD_REGEX.test(password);

  async function onSubmitValidation() {
    setLoading(true);
    if (!passwordIsValid) {
      setShowPasswordInvalidMsg(true);
      setLoading(false);
      return false;
    }
    setShowPasswordInvalidMsg(false);

    const res = await authService.updateUser({
      password: password,
    }, userId);
    if (res.success) {
      const getUser = await authService.getUser({ idUser: userId });
      history.push("ResultMessageScreen");
    } else if (res.errorMessage.noResponse) {
      history.push("/error", {
        params: noResponseParams,
      });
    } else {
      history.push("/error", {
        params: notOkPageParams(
          res.errorMessage.message + "\n " + t("resultScreens:tryAgainLater")
        ),
      });
    }
    setLoading(false);
  }


  return (
    <AppLayout
      headerLabel={t("common:headerForgotPassword")}
      headerBack={false}
      navigation={history}
      style={{backgroundColor:"#fff"}}
    >
      <View style={{ display: "flex", flex: 1, paddingTop: "3%" }}>
        <View style={styles.passwordContainer}>
          <TextInput
            onChangeText={onChangePassword}
            value={password}
            placeholder={t("authentication:newPassword") + "*"}
            textContentType="newPassword"
            passwordRules="minlength: 8; required: lower; required: digit; required: [#];"
            secureTextEntry={secureTextEntry}
            placeholderTextColor={DefaultTheme.COLORS.grey2}
            style={{ overflow: "hidden", width: "90%", fontSize: 16, outlineWidth: "0px", fontFamily: DefaultTheme.FONTS.Regular,fontSize:"18px" }}
          />
          <Pressable
            style={{ display: "flex", justifyContent: "center", width: "8%" }}
            onPress={() => {
              setSecureTextEntry(secureTextEntry ? false : true);
            }}
          >
            {secureTextEntry ? (
              <Password style={{ alignSelf: "center" }} />
            ) : (
              <Password2 style={{ alignSelf: "center" }} />
            )}
          </Pressable>
        </View>
        <SmallParagraph
          style={showPasswordInvalidMsg ? { color: DefaultTheme.COLORS.error } : null}
        >
          {t("errors:passwordInfo")}
        </SmallParagraph>
        <BottomBtnContainer>
          <Button
            text={t("buttons:setNewPassword")}
            element={password}
            onPress={onSubmitValidation}
            type="PRIMARY"
            loading={loading}
          />
        </BottomBtnContainer>
      </View>
    </AppLayout>
  );
}

const styles = StyleSheet.create({
  passwordContainer: {
    fontFamily: DefaultTheme.FONTS.Regular,
    height: 40,
    borderBottomWidth: 1,
    borderColor: DefaultTheme.COLORS.grey5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
