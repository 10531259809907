import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  View,
  StyleSheet,
  TextInput,
  Pressable,
} from "react-native";

import DefaultTheme from "../../themes";
import { ReactComponent as Password } from "../../assets/icons/password.svg";
import { ReactComponent as Password2 } from "../../assets/icons/password2.svg";
import Button from "../../components/Button";
import AppLayout from "../../appLayout/AppLayout";
import AuthService from "../../services/auth-service";
import BottomBtnContainer from "../../components/UI/BottomBtnContainer";
import SmallParagraph from "../../components/UI/SmallParagraph";

export default function CurrentPassword({ navigation, route }) {
    const { t } = useTranslation();
    const history = useHistory();

    const email = history.location?.email;
    const [password, onChangePassword] = useState("");
    const [secureTextEntry, setSecureTextEntry] = useState(true);
    const [showPasswordInvalidMsg, setShowPasswordInvalidMsg] = useState(false);
    const [loading, setLoading] = useState(false);

    async function onSubmitValidation() {
      setLoading(true);
      let res = await AuthService.verifyCurrentPassword({ 
        email,
        password, 
      });
      if(!res.success){
          setShowPasswordInvalidMsg(true);
          setLoading(false);
          return false;
      }
      setShowPasswordInvalidMsg(false);
      const userID = res.result.user._id;
      history.push({
        pathname: "/ForgotPassword/NewPassword",
        goBack: true,
        titleHeader: t("authentication:newPassword"),
        userId: userID,
      });
      setLoading(false);
    }
    return (
      <AppLayout
        headerLabel={t("common:headerCurrentPassword")}
        headerBack={true}
        route={route}
        navigation={navigation}
        style={{ backgroundColor: "#fff" }}
      >
        <View style={{ display: "flex", flex: 1, paddingTop: "3%" }}>
          <View style={styles.password_container}>
            <TextInput
              onChangeText={onChangePassword}
              value={password}
              placeholder={t("authentication:password") + "*"}
              textContentType="password"
              secureTextEntry={secureTextEntry}
              placeholderTextColor={DefaultTheme.COLORS.grey2}
              style={{ overflow: "hidden", width: "90%", fontSize: 16, outlineWidth: 0, fontFamily: DefaultTheme.FONTS.Regular,fontSize:"18px" }}
            />
            <Pressable
              style={{ display: "flex", justifyContent: "center", width: "8%" }}
              onPress={() => {
                setSecureTextEntry(secureTextEntry ? false : true);
              }}
            >
              {secureTextEntry ? (
                <Password style={{ alignSelf: "center" }} />
              ) : (
                <Password2 style={{ alignSelf: "center" }} />
              )}
            </Pressable>
          </View>
          <SmallParagraph
            style={
              showPasswordInvalidMsg
                ? { color: DefaultTheme.COLORS.error }
                : null
            }
          >
            {showPasswordInvalidMsg
              ? t("errors:passwordWrong") + " "
              : t("errors:currentPassword")}
          </SmallParagraph>
          <BottomBtnContainer>
            <Button
              text={t("buttons:setNewPassword")}
              element={password}
              onPress={onSubmitValidation}
              type="PRIMARY"
              loading={loading}
            />
          </BottomBtnContainer>
        </View>
      </AppLayout>
    );
}

const styles = StyleSheet.create({
  password_container: {
    fontFamily: DefaultTheme.FONTS.Regular,
    height: 40,
    borderBottomWidth: 1,
    borderColor: DefaultTheme.COLORS.grey5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
