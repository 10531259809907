import React, { useEffect, useState, useRef } from "react";

import { StyleSheet, SectionList, View, Animated } from "react-native-web";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppLayout from "../../appLayout/AppLayout";
import DefaultTheme from "../../themes";
import ResultMessages from "../../components/ResultMessages";
import H2Title from "../../components/UI/H2Title";
import {
  getFormattedDateDayMonthHour,
  getFormattedDateMonthYear,
} from "../../constants/utils/date";
import DataList from "../../components/DataList";
import DataListHeader from "../../components/DataListHeader";
import SplashScreen from "../SplashScreen";
import Button from "../../components/Button";

import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as NoPurchases } from "../../assets/images/purchases/no_purchases.svg";
import MainMenu from "../../components/Menu";
import ModalView from "../../components/Modal";
import localStorageUser from "../../localStorage/user";

import {
  noResponseParams,
  notOkPageParams,
} from "../../components/Helpers/ResultMessagesHelper";

import BuybyeService from "../../services/buybye-service";
import AuthService from "../../services/auth-service";

function PurchasesListRender({ shopListArr }) {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  function renderShopList({ item }) {
    function pressHandler() {
      history.push({
        pathname: "Purchases/PurchasesDetail",
        purchaseId: item._id,
        homeNavigation: false,
      });
    }

    return (
      <DataList
        onPress={() => pressHandler(item)}
        debt={
          typeof item.debt === "undefined" || item.debt === 0 ? false : true
        }
        title={{
          text: getFormattedDateDayMonthHour(item.endDate, i18n.language),
        }}
        titleRightInfo={
          item.totalProduct +
          " " +
          t("purchases:item") +
          (item.totalProduct > 1 ? "s" : "")
        }
        lineOneLeft={{
          label: t("purchases:purchase") + ": ",
          text: item.sessionUUID,
          color: DefaultTheme.COLORS.grey2,
        }}
        lineOneRight={{
          text:
            item.currency + " " + item.totalValue.toFixed(2).replace(".", ","),
          style: [
            styles.darker_text,
            { fontFamily: DefaultTheme.FONTS.Regular },
          ],
        }}
      />
    );
  }

  return (
    <AppLayout
      headerBack={false}
      logo={true}
      navigation={history}
      withScrollview={shopListArr.length === 0 ? true : false}
      styleToContainer={{ paddingHorizontal: 0 }}
      style={{ backgroundColor: "#fff" }}
    >
      <H2Title style={{ paddingTop: "20px" }}>Purchase history</H2Title>
        <View style={{ flex: 1, paddingHorizontal: DefaultTheme.GLOBAL_SPACES.paddingHorizontal }}>
          <SectionList
            sections={shopListArr}
            showsHorizontalScrollIndicator={false}
            renderItem={renderShopList}
            renderSectionHeader={({ section }) => (
              <DataListHeader
                title={getFormattedDateMonthYear(section.title, i18n.language)}
              />
            )}
            keyExtractor={(item) => item._id}
            bounces={false}
            stickySectionHeadersEnabled={true}
          />
        </View>
    </AppLayout>
  );
}

export default function PurchasesList() {
  const [shopListArr, setShopListArr] = useState();
  const { t } = useTranslation();
  const history = useHistory();

  const [openCloseMenu, setOpenCloseMenu] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [otherIdentifiers, setOtherIdentifiers] = useState("");
  const [email, setEmail] = useState("");
  const [notifications, setNotifications] = useState(false);

  const slide = useRef(new Animated.Value(0)).current;
  const [opacity] = useState(new Animated.Value(0));

  // Get user from storage to set states with user data
  useEffect(() => {
    async function getUserFromStorage() {
      const getUser = await localStorageUser.getUser();
      if (typeof getUser.result === "string") {
        getUser.result = JSON.parse(getUser.result);
      }
      if (getUser.result) {
        setId(getUser.result._id);
        setEmail(getUser.result.email);
        setOtherIdentifiers(getUser.result?.otherIdentifiers);
        setNotifications(getUser.result.notifications);
      }
    }
    getUserFromStorage();
  }, []);

  // Get shop list
  useEffect(() => {
    async function main() {
      let res = await BuybyeService.getShops(otherIdentifiers);
      if (res.success) {
        const sortedResult = [...res.result].sort(
          (a, b) => new Date(b.endDate) - new Date(a.endDate)
        );
        const orderedShopListByYM = [
          ...new Set(
            sortedResult.map(
              (shopList) =>
                shopList.endDate.split("-")[0] +
                "-" +
                shopList.endDate.split("-")[1]
            )
          ),
        ].map((date) => [
          date,
          [
            ...new Set(
              sortedResult.filter(
                (shopList) =>
                  shopList.endDate.split("-")[0] +
                    "-" +
                    shopList.endDate.split("-")[1] ===
                  date
              )
            ),
          ],
        ]);

        const orderedShopListByYMObj = orderedShopListByYM.map(
          ([title, data]) => ({
            title,
            data,
          })
        );
        setShopListArr(orderedShopListByYMObj);
      } else if (res.errorMessage.noResponse) {
         history.push("/error", {
          params: notOkPageParams(
             t("resultScreens:tryAgainLater")
          ),
         });
      } else {
        history.push("/error", {
          params: notOkPageParams(
            res.errorMessage.message + "\n " + t("resultScreens:tryAgainLater")
          ),
        });
      }
    }
    if (otherIdentifiers) {
      main();
    }
  }, [otherIdentifiers]);

  // Open/close menu
  useEffect(() => {
    if (openCloseMenu) {
      fadeIn();
      slideUp();
    } else {
      fadeOut();
      slideDown();
    }
  }, [openCloseMenu]);

  // Handle of switch of email receipt notifications to update users
  const handleChange = async (value) => {
    let res = await AuthService.updateUser(
      {
        notifications: value,
      },
      id
    );
    if (res.success) {
      await AuthService.getUser({ idUser: id });
    }
    setNotifications(value);
  };

  // Update user account with flag toDelete
   async function deleteAccount() {
     setLoading(true);
     let res = await AuthService.deleteUser({ toDelete: true }, id);
     if (res.success) {
       AuthService.logout();
       history.push("SignIn");
     } else if (res.errorMessage.noResponse) {
       history.push("/error", {
         params: noResponseParams,
       });
     } else {
       history.push("/error", {
         params: notOkPageParams(
           res.errorMessage.message + "\n " + t("resultScreens:tryAgainLater")
         ),
       });
     }
     setModalVisible(false);
     setLoading(false);
   }

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 0.5 seconds
    Animated.timing(opacity, {
      toValue: 0.5,
      duration: 500,
      useNativeDriver: true,
    }).start();
  };

  const fadeOut = () => {
    // Will change fadeAnim value to 0 in 0.5 seconds
    Animated.timing(opacity, {
      toValue: 0,
      duration: 0,
      useNativeDriver: true
    }).start();
  };

  const slideUpStyle = {
    transform: [{ translateY: slide }],
  };

  const slideUp = () => {
    Animated.timing(slide, {
      toValue: -330,
      duration: 400,
      useNativeDriver: true,
    }).start();
  }

  const slideDown = () => {
    Animated.timing(slide, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }

  const opacityFade = {
    opacity: opacity,
  };

  if (!shopListArr) {
    return <SplashScreen />;
  } else {
    return (
      <>
        <View>
          <Animated.View
            style={[
              opacityFade,
              {
                display: openCloseMenu ? "block" : "none",
                backgroundColor: "#000",
                height: "100%",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 10,
              },
            ]}
          />
          {shopListArr.length > 0 ? (
            <PurchasesListRender shopListArr={shopListArr} />
          ) : (
            <AppLayout
              headerBack={false}
              logo={true}
              backHome={false}
              navigation={history}
              style={{ backgroundColor: "#fff" }}
            >
              <View style={styles.container}>
                <ResultMessages
                  image={[<NoPurchases />]}
                  title={t("purchases:noPurchasesTitle")}
                  text={t("purchases:noPurchasesText")}
                />
              </View>
            </AppLayout>
          )}

          <ModalView
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            title={t("home:logoutModalTitle")}
            subTitle={t("home:logoutModalText")}
            buttons={[
              {
                title: t("buttons:logout"),
                type: "SECONDARY",
                onPress: () => {
                  AuthService.logout();
                  history.push("SignIn");
                },
              },
            ]}
          />
          <ModalView
            modalVisible={deleteModalVisible}
            setModalVisible={setDeleteModalVisible}
            title={t("authentication:deleteAccountModalTitle")}
            subTitle={t("authentication:deleteAccountModalText")}
            buttons={[
              {
                title: t("buttons:deleteAccount"),
                type: "SECONDARY",
                onPress: () => deleteAccount(),
                loading: loading,
                element: loading ? "" : null,
              },
            ]}
          />
        </View>
        <Animated.View style={[slideUpStyle]}>
          <View style={styles.bottom_container}>
            <Button
              onPress={() => {
                setOpenCloseMenu(!openCloseMenu);
              }}
              type="SECONDARY_ROUND"
              Icon={openCloseMenu ? Close : Menu}
            />
          </View>
        </Animated.View>
        <MainMenu
          menuVisible={openCloseMenu}
          setMenuVisible={setOpenCloseMenu}
          setModalVisible={setModalVisible}
          setDeleteModalVisible={setDeleteModalVisible}
          toggle={notifications}
          handleChange={handleChange}
          email={email}
        ></MainMenu>
      </>
    );
  }
}

const styles = StyleSheet.create({
  darker_text: {
    fontFamily: DefaultTheme.FONTS.Regular,
    fontSize: 16,
    lineHeight: 17.78,
    color: DefaultTheme.COLORS.grey5,
  },
  container: {
    backgroundColor: "white",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  carousel_container: {
    flex: 1,
    zIndex: 1,
    position: "absolute",
    left: 0,
    top: 0,
  },
  bottom_container: {
    position: "absolute",
    zIndex: 9999,
    bottom: 50,
    right: 20,
  },
});
