import React, { useEffect, useContext } from "react";
import AppLayout from "../../appLayout/AppLayout";
import { useHistory } from "react-router-dom";
import { ReactComponent as SvgComponent } from "../../assets/images/result_screens/something_wrong.svg";
import { View, StyleSheet } from "react-native-web";
import Button from "../../components/Button";
import H1Title from "../../components/UI/H1Title";
import Paragraph from "../../components/UI/Paragraph";
import { useTranslation } from "react-i18next";
import BottomBtnContainer from "../../components/UI/BottomBtnContainer";
import AuthService from "../../services/auth-service";

export default function UserNotValidated() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <AppLayout header={false} style={{ backgroundColor: "#fff" }}>
      <View style={{ flex: 1, display: "flex" }}>
        <View style={{ alignSelf: "center", marginTop: "24vh" }}>
          <SvgComponent />
        </View>
        <View style={styles.text_container}>
          <H1Title style={{ marginTop: 30 }}>
            {t("resultScreens:userNotValidatedTitle")}
          </H1Title>
          <Paragraph>{t("resultScreens:userNotValidated")}</Paragraph>
        </View>
        <BottomBtnContainer style={{ flex: 2 }}>
          <View style={{ marginTop: 24 }}>
            <Button
              text={t("buttons:validate")}
              onPress={async () => {
/*                 var res = await AuthService.getUser({
                  email: history.location?.email,
                }); */
                //if (res.success) {
                  //let id = res.result._id;
                  await AuthService.newValidationCode({
                    email: history.location?.email,
                  });
                  history.push({
                    pathname: "/SignUp/AccountValidation",
                    email: history.location?.email,
                    //id: id,
                    goBack: true,
                  });
                //}
              }}
              type="PRIMARY"
            />
          </View>
          <View style={{ marginTop: 24 }}>
            <Button
              text={t("buttons:logIn")}
              onPress={async () => {
                history.goBack();
              }}
              type="LINK"
            />
          </View>
        </BottomBtnContainer>
      </View>
    </AppLayout>
  );
}

const styles = StyleSheet.create({
    image_container: {
        flex: 4,
        justifyContent: "center",
        alignItems: "center",
    },
    text_container: {
        flex: 2,
        justifyContent: "flex-start",
        paddingTop: 0
    },
});
