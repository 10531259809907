import React, { useEffect } from "react";
import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Animated,
} from "react-native";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Switch from "./Switch";

import DefaultTheme from "../themes";

const MainMenu = ({
  email,
  menuVisible,
  setMenuVisible,
  setModalVisible,
  setDeleteModalVisible,
  toggle,
  handleChange,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

   const handleChangeMenu = (event) => {
     handleChange(event.target.checked);
   };

  let menuItems = [
    {
      id: "01",
      text: "Email Receipt Notifications",
      nav: () => {},
      toggle: {toggle},
      active: true,
    },
    {
      id: "02",
      text: "Change Password",
      nav: () => {
        setMenuVisible(false);
        history.push({
          pathname: "/CurrentPassword",
          email: email
        })
      },
      module: "changePassword",
      active: true,
    },
    {
      id: "03",
      text: "Delete Account",
      nav: () => {
        setMenuVisible(false);
        fadeOut();
        slideDown();
        setDeleteModalVisible(true);
      },
      module: "deleteAccount",
      active: true,
    },
    {
      id: "04",
      text: t("menu:logout"),
      nav: () => {
        setMenuVisible(false);
        fadeOut();
        slideDown();
        setModalVisible(true);
      },
      active: true,
    },
  ];

  const slide = React.useRef(new Animated.Value(0)).current;
  const [opacity] = React.useState(new Animated.Value(0));

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 0.5 seconds
    Animated.timing(opacity, {
      toValue: 0.5,
      duration: 500,
      useNativeDriver: true,
    }).start();
  };

  const fadeOut = () => {
    // Will change fadeAnim value to 0 in 0.5 seconds
    Animated.timing(opacity, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start();
  };

  const slideUp = () => {
    Animated.timing(slide, {
      toValue: 0,
      duration: 400,
      useNativeDriver: true,
    }).start();
  };

  const slideDown = () => {
    Animated.timing(slide, {
      toValue: 500,
      duration: 0,
      useNativeDriver: true,
    }).start();
  };

  const slideUpStyle = {
    transform: [{ translateY: slide }],
  };

  useEffect(() => {
    if (menuVisible) {
      fadeIn();
      slideUp();
    } else {
      fadeOut();
      slideDown();
    }
  }, [menuVisible]);

  return (
    <>
      <View style={[{ display: menuVisible ? "block" : "none" }]}>
        <Animated.View style={[slideUpStyle, styles.modalView]}>
          {menuItems.map((item) => {
            return (
              item.active &&
              item.nav &&
              (typeof item.submenu === "undefined" || item.submenu === "") && (
                <Pressable key={item.id} onPress={item.nav}>
                  <View style={styles.inner}>
                    <Text
                      style={[
                        styles.menutext,
                        item.badge && { paddingRight: 10 },
                      ]}
                    >
                      {item.text}
                    </Text>
                    {item.toggle ? (
                      <Switch
                        checked={item.toggle}
                        defaultChecked={item.toggle.toggle}
                        onChange={handleChangeMenu}
                      />
                    ) : null}
                  </View>
                </Pressable>
              )
            );
          })}
        </Animated.View>
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  menu: {
    backgroundColor: DefaultTheme.COLORS.white,
    paddingVertical: 20,
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: -10,
    width: "100%",
    height: "auto",
    display: "flex",
    flex: 1,
  },
  menutext: {
    color: DefaultTheme.COLORS[DefaultTheme.COLORS.grey5],
    paddingBlock: 20,
    fontSize: 22,
    fontFamily: DefaultTheme.FONTS[DefaultTheme.MENU.font_family],
  },
  submenu_language_container: {
    display: "flex",
    flex: 1,
    position: "relative",
    width: "100%",
    overflow: "hidden",
    backgroundColor:
      DefaultTheme.COLORS[DefaultTheme.MENU.language_submenu_background],
  },
  scroll_shadow: {
    height: 65,
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    flex: 1,
  },
  inner: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  badge: {
    backgroundColor: DefaultTheme.COLORS.color5,
    color: DefaultTheme.COLORS.white,
    width: 18,
    height: 18,
    borderRadius: 50,
  },
  badge_text: {
    fontFamily: DefaultTheme.FONTS.Bold,
    fontSize: 10,
    lineHeight: 12,
    color: DefaultTheme.COLORS.white,
    textAlign: "center",
    paddingTop: 3,
    marginLeft: -1,
  },
  close_container: {
    alignSelf: "flex-end",
    marginBottom: 26,
  },
  containerModal: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: DefaultTheme.COLORS.grey5,
  },
  modalView: {
    position: "absolute",
    bottom: 0,
    backgroundColor: DefaultTheme.COLORS.white,
    width: "100%",
    paddingTop: 26,
    paddingBottom: 64,
    paddingHorizontal: "20px",
    borderTopRightRadius: "16px",
    borderTopLeftRadius: "16px"
  },
});
export default MainMenu;
