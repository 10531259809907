import React from "react";
import AppLayout from "../../appLayout/AppLayout";
import { useHistory } from "react-router-dom";
import {ReactComponent as SvgComponent} from "../../assets/images/send_receipt/Receipt.svg";
import { View, StyleSheet } from "react-native-web";
import Button from "../../components/Button";
import H1Title from "../../components/UI/H1Title";
import Paragraph from "../../components/UI/Paragraph";
import { useTranslation } from "react-i18next";
import BottomBtnContainer from "../../components/UI/BottomBtnContainer";

export default function ResultMessageScreen() {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <AppLayout
            header={false}
            style={{ backgroundColor: "#fff" }}
        >
            <View style={{ flex: 1, display: "flex" }}>
                <View style={{ alignSelf: "center", marginTop: "24vh" }}>
                    <SvgComponent />
                </View>
                <View style={styles.text_container}>
                    <H1Title style={{ marginTop: 30 }}>Receipt Was Sent To Your Email</H1Title>
                    <Paragraph>The receipt was sent to your email. If you want to access to your purchase history, please consider creating an account. </Paragraph>
                </View>
                <BottomBtnContainer style={{ flex: 2 }}>
                    <View style={{ marginTop: 24 }}>
                        <Button
                            text={"Create Account"}
                            onPress={async () => { 
                                history.push({
                                  pathname: "/Signup",
                                  email: history.location?.email,
                                  card: history.location?.card,
                                  purchase: history.location?.purchaseId,
                                });
                            }}
                            type="LINK"
                        />
                    </View>
                </BottomBtnContainer>
            </View>
        </AppLayout>
    );
}

const styles = StyleSheet.create({
    image_container: {
        flex: 4,
        justifyContent: "center",
        alignItems: "center",
    },
    text_container: {
        flex: 2,
        justifyContent: "flex-start",
        paddingTop: 0
    },
});
